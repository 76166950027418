define("adt-wss/pods/components/dashboard/account/contacts/location-number/component", ["exports", "adt-wss-common/utils/ember-helpers", "adt-wss-common/mixins/with-fsm", "adt-wss-common/classes/status-message", "adt-wss-common/utils/fp", "adt-wss-common/utils/localization", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/common-fsm-state"], function (_exports, _emberHelpers, _withFsm, _statusMessage, _fp, _localization, _myadtHelpers, _commonFsmState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EDIT_STATE = 'EDIT_STATE';
  const SUBMITTING_STATE = 'SUBMITTING_STATE';
  const EDIT_ACTION = 'EDIT_ACTION';
  const SUBMIT_ACTION = 'SUBMIT_ACTION';
  const RESOLVE_ACTION = 'RESOLVE_ACTION';
  const REJECT_ACTION = 'REJECT_ACTION';
  const CANCEL_ACTION = 'CANCEL_ACTION';
  const CANCEL_EDIT_MODE_ACTION = 'CANCEL_EDIT_MODE_ACTION';
  const ON_ENTER_ACTION = 'ON_ENTER_ACTION';
  const ON_ENTER_STATE = 'ON_ENTER_STATE';
  const DELETE_ACTION = 'DELETE_ACTION';
  const DELETE_STATE = 'DELETE_STATE';
  const ON_DELETE_ACTION = 'ON_DELETE_ACTION';
  const ON_DELETE_STATE = 'ON_DELETE_STATE';
  const fsmModel = {
    transitions: {
      IDLE_STATE: {
        EDIT_ACTION: EDIT_STATE,
        FETCH_DATA_ACTION: _commonFsmState.LOADING_STATE,
        DELETE_ACTION: DELETE_STATE
      },
      [_commonFsmState.LOADING_STATE]: {
        [_commonFsmState.SUCCESS_ACTION]: _commonFsmState.IDLE_STATE,
        [_commonFsmState.FAILURE_ACTION]: _commonFsmState.IDLE_STATE
      },
      EDIT_STATE: {
        [ON_ENTER_ACTION]: ON_ENTER_STATE,
        [CANCEL_ACTION]: _commonFsmState.IDLE_STATE,
        [CANCEL_EDIT_MODE_ACTION]: _commonFsmState.IDLE_STATE
      },
      DELETE_STATE: {
        [ON_DELETE_ACTION]: ON_DELETE_STATE,
        [CANCEL_ACTION]: _commonFsmState.IDLE_STATE,
        [CANCEL_EDIT_MODE_ACTION]: _commonFsmState.IDLE_STATE
      },
      ON_DELETE_STATE: {
        [SUBMIT_ACTION]: SUBMITTING_STATE,
        [ON_DELETE_ACTION]: ON_DELETE_STATE,
        [CANCEL_ACTION]: _commonFsmState.IDLE_STATE
      },
      ON_ENTER_STATE: {
        [SUBMIT_ACTION]: SUBMITTING_STATE,
        [ON_ENTER_ACTION]: ON_ENTER_STATE,
        [CANCEL_ACTION]: _commonFsmState.IDLE_STATE
      },
      SUBMITTING_STATE: {
        [RESOLVE_ACTION]: _commonFsmState.IDLE_STATE,
        [REJECT_ACTION]: EDIT_STATE,
        [CANCEL_ACTION]: _commonFsmState.IDLE_STATE,
        [ON_ENTER_ACTION]: ON_ENTER_STATE
      }
    }
  };
  const defaultProps = {
    contact: {},
    phoneTypes: [{
      text: 'Select',
      value: null
    }, {
      text: 'Home',
      value: 'H'
    }, {
      text: 'Work',
      value: 'W'
    }, {
      text: 'Mobile',
      value: 'M'
    }, {
      text: 'Pager',
      value: 'P'
    }, {
      text: 'Landline',
      value: 'L'
    }],
    phoneTypesToAdd: [{
      text: 'Select',
      value: null
    }, {
      text: 'Landline',
      value: 'L'
    }, {
      text: 'Mobile',
      value: 'M'
    }]
  };
  /**
   * @module Component/Dashboard/Account/Contacts/LocationNumber
   *
   * @prop {Object[]} sitePhoneNumbers {@link Services/ContactData.sitePhoneNumbers}
   * @prop {Function} determinePhoneDuplication
   * @prop {Boolean} systemPhoneNumbersDuplication
   * @example
   * {@lang xml} {{ dashboard/account/contacts/location-number
   *  determinePhoneDuplication=(action 'determinePhoneDuplication')
   *  setMessages=(action 'setMessages')
   * }}
   */

  var _default = Ember.Component.extend(_withFsm.default, defaultProps, {
    classNames: 'contacts-location-numbers',
    classNameBindings: ['systemPhoneNumbersDuplication'],
    contactData: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    mwd: Ember.inject.service('modal-window-dispatcher'),
    editing: false,
    deleting: false,
    editType: null,
    isAddPrimary: false,
    isAddSecondary: false,
    initAction: _commonFsmState.FETCH_DATA_ACTION,
    currentState: (0, _emberHelpers.computedApplyFunction)(newStatus => newStatus ? EDIT_STATE : _commonFsmState.IDLE_STATE, 'new'),
    IS_IDLE_STATE: (0, _emberHelpers.isState)(_commonFsmState.IDLE_STATE),
    IS_EDIT_STATE: (0, _emberHelpers.isState)(EDIT_STATE),
    IS_DELETE_STATE: (0, _emberHelpers.isState)(DELETE_STATE),
    IS_ON_DELETE_STATE: (0, _emberHelpers.isState)(ON_DELETE_STATE),
    activeESCState: EDIT_STATE,
    cancelESCState: CANCEL_EDIT_MODE_ACTION,
    IS_LOADING_STATE: (0, _emberHelpers.isState)(_commonFsmState.LOADING_STATE),
    fsmModel,
    setEditStatus: () => {},
    phonesDuplicationTable: Ember.computed.readOnly('contactData.phonesDuplicationTable'),
    sitePhoneNumbers: Ember.computed.oneWay('contactData.sitePhoneNumbers'),
    allContactNumbers: Ember.computed.oneWay('contactData.allContactNumbers'),
    processedContactNumbers: (0, _emberHelpers.computedApplyFunction)((allContactNumbers, originalValues = {}) => Object.values(originalValues).reduce((acc, number) => {
      const n = (0, _myadtHelpers.removePhoneMask)(number);
      const i = acc.indexOf(n);

      if (i < 0) {
        return acc;
      }

      return acc.filter((e, idx) => idx !== i);
    }, allContactNumbers), 'allContactNumbers', 'originalValues'),
    startTime: null,
    originalValues: null,
    hadDupe: false,
    dupesCleared: false,
    dupePrimary: false,
    dupeSecondary: false,
    // sitePhoneNumbers: omix({}, sitePhoneNumbers),
    addNumber: false,
    primaryError: false,
    secondaryError: false,
    isLoading: false,
    messages: null,
    signalChatOptTypes: [{
      text: 'Select',
      value: null
    }, {
      text: 'Yes',
      value: true
    }, {
      text: 'No',
      value: false
    }],
    chatVideoSharingFlagTypes: [{
      text: 'Select',
      value: null
    }, {
      text: 'Yes',
      value: true
    }, {
      text: 'No',
      value: false
    }],

    // systemPhoneNumbersDuplication: this.get('systemPhoneNumbersDuplication'),

    /**
     * Gets sheculed reports list and locations
     * Retrieves '/api/v2/auth/contacts' - this API is needed for computing duplicate numbers
     * Retrieves '/api/v2/auth/site/numbers'
     * Triggered as initial action
     *
     * @function
     * @param {Boolean} forceRequest - option for cache skipping
     * @returns {Promise}
     */
    [_commonFsmState.FETCH_DATA_ACTION](forceRequest) {
      const fsm = this.get('fsm');
      const deliverOptions = {
        forceRequest
      };
      return this.deliverData('contactData.sitePhoneNumbers', s => s.getSiteNumbers(), deliverOptions).then(results => fsm.dispatch(_commonFsmState.SUCCESS_ACTION, results)).catch(e => fsm.dispatch(_commonFsmState.FAILURE_ACTION, e));
    },

    /**
     * Updates `sitePhoneNumbers`
     * Triggered on `FETCH_DATA_ACTION`
     *
     * @function
     * @param {Object[]} sitePhoneNumbers {@link Services/ContactData.sitePhoneNumbers}
     */
    [_commonFsmState.SUCCESS_ACTION](data) {
      this.set('sitePhoneNumbers', data);
      this.set('originalValues', JSON.parse(JSON.stringify(data)));
    },

    [CANCEL_ACTION]() {
      this.set('editing', false);
      this.set('deleting', false);
      this.set('editType', null);
      this.set('isAddPrimary', false);
      this.set('isAddSecondary', false);
      this.setProperties({
        dupesCleared: false,
        dupePrimary: false,
        dupeSecondary: false
      });
      this.set('messages', null); // this.set('sitePhoneNumbers', this.get('originalValues'))

      const fsm = this.get('fsm');
      fsm.dispatch(_commonFsmState.FETCH_DATA_ACTION, true);
    },

    /**
     * Opens model to edit location phone numbers
     * @param {Boolean} addNumber indicates that there is only a primary phone, and a secondary is being added
     */
    [EDIT_ACTION](type) {
      this.set('editing', true);
      this.set('editType', type);
      const addNumber = type === "3" ? true : false;
      const PRIMARY_SELECTION = 'primary';
      const SECONDARY_SELECTION = 'secondary';
      const {
        mwd,
        fsm,
        sitePhoneNumbers,
        processedContactNumbers
      } = this.getProperties('mwd', 'fsm', 'sitePhoneNumbers', 'processedContactNumbers');
      const showErrors = (0, _myadtHelpers.showModalErrors)(fsm, mwd);

      const onClose = () => {
        this.get('fsm').dispatch(CANCEL_ACTION); // mwd.hide();
      };

      if (addNumber) {
        if (!sitePhoneNumbers.primary) {
          (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Alarm Contacts', 'Add Primary Phone Clicked']);
          this.set('isAddPrimary', true);
        } else {
          (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Alarm Contacts', 'Add Secondary Phone Clicked']);
          this.set('isAddSecondary', true);
        }
      } else {
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Alarm Contacts', 'Edit Location Numbers Clicked']);
      }
    },

    [DELETE_ACTION]() {
      this.set('deleting', true);
    },

    [ON_DELETE_ACTION]() {
      const {
        fsm,
        type
      } = this.getProperties('fsm', "type");
      const addNumber = type === "3" ? true : false;

      const showErrors = obj => {
        this.set('messages', [(0, _localization.toExpandedString)(obj.errors)]);
      }; // this.actions.onEnter.call(this);


      if (this.get('messages')) {
        return;
      }

      const spn = this.get('sitePhoneNumbers');
      return fsm.dispatch(SUBMIT_ACTION, {
        primary: spn.primary,
        secondary: null,
        primaryChatFlag: spn.primaryChatFlag,
        secondaryChatFlag: null,
        primaryVideoSharingFlag: spn.primaryVideoSharingFlag,
        secondaryVideoSharingFlag: null,
        phoneType1: spn.phoneType1,
        phoneType2: ''
      }).then(data => {
        return fsm.dispatch(RESOLVE_ACTION, data);
      }).then(() => {
        return fsm.dispatch(_commonFsmState.FETCH_DATA_ACTION);
      }).catch(results => {
        return showErrors(results, REJECT_ACTION);
      });
    },

    [ON_ENTER_ACTION]() {
      const PRIMARY_SELECTION = 'primary';
      const SECONDARY_SELECTION = 'secondary';
      const {
        mwd,
        fsm,
        sitePhoneNumbers,
        processedContactNumbers,
        type
      } = this.getProperties('mwd', 'fsm', 'sitePhoneNumbers', 'processedContactNumbers', "type");
      const addNumber = type === "3" ? true : false;

      const showErrors = obj => {
        this.set('messages', [(0, _localization.toExpandedString)(obj.errors)]);
      };

      this.actions.onEnter.call(this);

      if (this.get('messages')) {
        return;
      }

      const spn = this.get('sitePhoneNumbers');
      return fsm.dispatch(SUBMIT_ACTION, {
        primary: spn.primary,
        secondary: spn.secondary,
        primaryChatFlag: spn.primaryChatFlag,
        secondaryChatFlag: spn.secondaryChatFlag,
        primaryVideoSharingFlag: spn.primaryVideoSharingFlag,
        secondaryVideoSharingFlag: spn.secondaryVideoSharingFlag,
        phoneType1: spn.phoneType1,
        phoneType2: spn.phoneType2
      }).then(data => {
        return fsm.dispatch(RESOLVE_ACTION, data);
      }).then(() => {
        return fsm.dispatch(_commonFsmState.FETCH_DATA_ACTION);
      }).catch(results => {
        return showErrors(results, REJECT_ACTION);
      });
    },

    /**
     * calls {@link Services/ContactData.updateSiteNumbers}
     * @param {Object} numbers
     * @param {String} numbers.primary
     * @param {String} numbers.secondary
     */
    [SUBMIT_ACTION]({
      primary,
      secondary,
      primaryChatFlag,
      secondaryChatFlag,
      primaryVideoSharingFlag,
      secondaryVideoSharingFlag,
      phoneType1,
      // New parameter
      phoneType2 // New parameter

    }) {
      const sitePhoneNumbersOrigin = this.get('originalValues');
      const isPrimary = primary !== sitePhoneNumbersOrigin.primary || primaryChatFlag !== sitePhoneNumbersOrigin.primaryChatFlag || phoneType1 && phoneType1 !== sitePhoneNumbersOrigin.phoneType1; // set by default, if it was not updated

      if (isPrimary && !phoneType1) {
        this.set("sitePhoneNumbers.phoneType1", 'L');
        sitePhoneNumbersOrigin.phoneType1 = 'L';
        phoneType1 = 'L';
      }

      if (secondary && !phoneType2) {
        this.set("sitePhoneNumbers.phoneType2", 'L');
        phoneType2 = 'L';
      }

      if (!isPrimary && !secondary && secondary === sitePhoneNumbersOrigin.secondary && sitePhoneNumbersOrigin.secondaryChatFlag === secondaryChatFlag && sitePhoneNumbersOrigin.phoneType2 === phoneType2 // Check for phoneType2 equality
      ) {
          if (this.get('editType') === "1") {
            this.set('primaryError', true);
          } else if (this.get('editType') === "1") {
            this.set('secondaryError', true);
          } else {
            if (!this.get('originalValues') && this.get('originalValues') && !this.get('originalValues').primary) {
              this.set('primaryError', true);
            } else {
              this.set('secondaryError', true);
            }
          }

          return Promise.reject({
            errors: ['No changes were made']
          });
        }

      return this.get('contactData').updateSiteNumbers({
        primary,
        secondary,
        isPrimary,
        primaryChatFlag,
        secondaryChatFlag,
        primaryVideoSharingFlag,
        secondaryVideoSharingFlag,
        phoneType1: phoneType1,
        // Include phoneType1 in the payload
        phoneType2: phoneType2 // Include phoneType2 in the payload

      }, this.get('startTime'));
    },

    /**
     * closes modal and shows success message
     */
    [RESOLVE_ACTION]() {
      this.setMessages([new _statusMessage.default('Information successfully updated.', 'success')]);
      const mwd = this.get('mwd');
      mwd.stopLoading();
      mwd.hide();
      this[CANCEL_ACTION]();
    },

    [REJECT_ACTION]() {
      this.get('mwd').stopLoading();
    },

    actions: {
      dispatch() {
        this.get('fsm').dispatch(...arguments);
      },

      setEditStatus(value) {
        this.set('editing', value);
      },

      changePhoneTypeHandler(type, value) {
        this.set("sitePhoneNumbers.".concat(type), value);
      },

      detectPhoneType(type) {
        const {
          text
        } = this.get('phoneTypes').find(el => el.value === type) || {
          text: ''
        };
        return text;
      },

      detectSignalChatOptType(type) {
        const {
          text
        } = type != null && this.get('signalChatOptTypes').find(el => el.value != null && el.value.toString() === type.toString()) || {
          text: 'Not specified'
        };
        return text;
      },

      detectChatVideoSharingFlagType(type) {
        const {
          text
        } = type !== null && this.get('chatVideoSharingFlagTypes').find(el => el.value != null && el.value.toString() === type.toString()) || {
          text: 'Not specified'
        };
        return text;
      },

      changeSignalChatOptTypeHandler(type, value) {
        if (value === "false") {
          this.set("sitePhoneNumbers.".concat(type, "VideoSharingFlag"), null);
        }

        this.set("sitePhoneNumbers.".concat(type, "ChatFlag"), value);
      },

      changeChatVideoSharingFlagHandler(type, value) {
        this.set("sitePhoneNumbers.".concat(type, "VideoSharingFlag"), value);
      },

      isBooleanEqual(value1, value2) {
        if (value1 == null && value2 == 'Select') {
          return true;
        }

        return value1 !== null && value1 !== undefined && value2 !== null && value2 != undefined && value1.toString() === value2.toString(); // return value1 == value2;
      },

      handleOnLoad() {
        if (!this.sitePhoneNumbers.phoneType1) {
          this.changePhoneTypeHandler('phoneType1', 'L');
        }
      },

      onEnter() {
        const PRIMARY_SELECTION = 'primary';
        const SECONDARY_SELECTION = 'secondary';
        const {
          mwd,
          fsm,
          sitePhoneNumbers,
          processedContactNumbers,
          type
        } = this.getProperties('mwd', 'fsm', 'sitePhoneNumbers', 'processedContactNumbers', "type");
        const addNumber = type === "3" ? true : false;
        this.set("messages", null);

        const showErrors = obj => {
          this.set('messages', [(0, _localization.toExpandedString)(obj.errors)]);
        };

        const duplicateNumTest = (inputPrimary, inputSecondary, inputSelected, currentSelection, recursiveCheck = true) => {
          const currentInputs = [inputPrimary, inputSecondary].map(_myadtHelpers.removePhoneMask);
          const phoneNumbersList = processedContactNumbers.concat(currentInputs);
          const dupesInInput = currentInputs.filter(n => Boolean(n) && n === (0, _myadtHelpers.removePhoneMask)(inputSelected));
          const dupesInSystem = phoneNumbersList.filter(n => Boolean(n) && n === (0, _myadtHelpers.removePhoneMask)(inputSelected));
          const oppositeSelection = currentSelection === PRIMARY_SELECTION ? SECONDARY_SELECTION : PRIMARY_SELECTION;

          if (dupesInInput.length > 1 || dupesInSystem.length > 1) {
            if (currentSelection === 'primary') {
              if (inputPrimary === sitePhoneNumbers.primary && dupesInSystem.length === 1) return; // set(body, 'primaryDupeState', 'duplicate');
              // set(body, 'dupesCleared', false);

              this.set('primaryDupeState', 'duplicate');
              this.set('dupesCleared', false);
              this.setProperties({
                dupePrimary: true,
                hadDupe: true
              });
            } else {
              if (inputSecondary === sitePhoneNumbers.secondary && dupesInSystem.length === 1) return; // set(body, 'secondaryDupeState', 'duplicate');
              // set(body, 'dupesCleared', false);

              this.set('secondaryDupeState', 'duplicate');
              this.set('dupesCleared', false);
              this.set('primaryError', true);
              this.set('secondaryError', true);
              this.setProperties({
                dupeSecondary: true,
                hadDupe: true
              });
              return;
            }
          } else if (this.get('hadDupe')) {
            if (currentSelection === 'primary' && this.get('dupePrimary')) {
              // set(body, 'primaryDupeState', 'cleared');
              this.set('primaryDupeState', 'cleared');
              this.set('dupePrimary', false);
            } else if (currentSelection === 'secondary' && this.get('dupeSecondary')) {
              // set(body, 'secondaryDupeState', 'cleared');
              this.set('secondaryDupeState', 'cleared');
              this.set('dupeSecondary', false);
            }
          }

          if (!this.get('dupePrimary') && !this.get('dupeSecondary') && this.get('hadDupe')) {
            this.set('dupesCleared', true); // set(body, 'dupesCleared', true);
          }

          if (recursiveCheck) {
            duplicateNumTest(inputPrimary, inputSecondary, oppositeSelection === PRIMARY_SELECTION ? inputPrimary : inputSecondary, oppositeSelection, false);
          }
        };

        this.set('primaryDupeState', '');
        this.set('secondaryDupeState', '');
        const spn = this.get('sitePhoneNumbers');
        this.set('primaryError', false);
        this.set('secondaryError', false);
        [PRIMARY_SELECTION, SECONDARY_SELECTION].forEach(type => duplicateNumTest(this.get('sitePhoneNumbers.primary'), this.get('sitePhoneNumbers.secondary'), this.get('sitePhoneNumbers[type]'), type));

        if (!spn.primary) {
          this.set('primaryError', true);
          return showErrors({
            errors: 'A primary contact number is required.'
          });
        }

        if (!(0, _myadtHelpers.validatePhoneNumber)(spn.primary)) {
          this.set('primaryError', true);
        } // only checks validates an empty secondary number if `addNumber` == true


        if ((spn.secondary || addNumber && sitePhoneNumbers.primary) && !(0, _myadtHelpers.validatePhoneNumber)(spn.secondary)) {
          this.set('secondaryError', true);
        }

        if (this.get('primaryError') || this.get('secondaryError')) {
          if (this.get('hadDupe')) {
            return showErrors({
              errors: "The highlighted phone number already exists in your Emergency Contacts.Duplicate phone numbers are not permitted as they may slow emergency response time."
            });
          } else {
            (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Error Messages', '/auth/contacts.jsp', (0, _localization.toExpandedString)('Contacts.invalidPhoneNumber')]);
            return showErrors({
              errors: 'Contacts.invalidPhoneNumber'
            });
          }
        }
      }

    },

    didReceiveAttrs() {
      this._super(...arguments);

      this.set('startTime', Date.now());
    }

  });

  _exports.default = _default;
});