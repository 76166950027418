define("adt-wss/services/contact-data", ["exports", "ember-copy", "adt-wss-common/utils/fp", "adt-wss/config/environment", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/ember-helpers", "adt-wss/services/app-dispatcher"], function (_exports, _emberCopy, _fp, _environment, _myadtHelpers, _emberHelpers, _appDispatcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const clearCacheArgs = {
    sitePhoneNumbers: null,
    originalValues: null,
    addlContacts: null,
    preDispatchContacts: null,
    postDispatchContacts: null,
    pmocData: null,
    phoneConsents: null,
    paperlessOption: null,
    travelReminders: null,
    restrictedOverrides: false
  };
  /**
   * API service and data store for
   * PMOC data and Site Contact information
   * @module Services/ContactData
   *
   * @prop {Boolean} restrictedOverrides
   * @prop {Object} sitePhoneNumbers
   * @prop {String} sitePhoneNumbers.primary
   * @prop {String} sitePhoneNumbers.secondary
   * @prop {Object[]} addlContacts
   * @prop {Object} pmocData
   * @prop {PmocContact[]} pmocData.primary
   * @prop {PmocContact[]} pmocData.service
   * @prop {PmocContact[]} pmocData.billing
   * @prop {Object} paperlessOption
   * @prop {Boolean} paperlessOption.enrolled
   * @prop {String} paperlessOption.pending 'A': add, 'D': delete, 'U': update
   * @prop {TravelReminders[]} travelReminders
   */

  var _default = Ember.Service.extend(Ember.Evented, (0, _myadtHelpers.clearCache)(clearCacheArgs), {
    apiService: Ember.inject.service(),
    currentLocation: Ember.inject.service(),
    impersonationData: Ember.inject.service(),
    mmbIncidents: Ember.inject.service(),
    promotionData: Ember.inject.service(),
    authInfo: Ember.inject.service(),
    ET: Ember.inject.service('event-tagging'),
    sitePhoneNumbers: null,
    originalValues: null,
    addlContacts: null,
    preDispatchContacts: null,
    postDispatchContacts: null,
    pmocData: null,
    paperlessOption: null,
    travelReminders: null,
    restrictedOverrides: false,
    phoneConsents: null,
    allContactNumbers: Ember.computed('sitePhoneNumbers', 'addlContacts', function () {
      const allNumbers = [(0, _myadtHelpers.removePhoneMask)(this.sitePhoneNumbers.primary)];
      if (this.sitePhoneNumbers.secondary) allNumbers.push((0, _myadtHelpers.removePhoneMask)(this.sitePhoneNumbers.secondary));
      this.addlContacts.map(contact => contact.phoneNumbers).map(phoneNum => phoneNum.map(prePost => (0, _myadtHelpers.removePhoneMask)(prePost.number) + prePost.extension)).forEach(finalNum => finalNum.forEach(n => allNumbers.push(n)));
      return allNumbers;
    }),
    prefCenterPilot: (0, _emberHelpers.computedApplyFunction)(p => (p || []).some(el => el.promotionType === 'MYADT_PREF_CENTER'), 'promotionData.promotions'),
    phonesDuplication: (0, _emberHelpers.computedApplyFunction)(table => Object.values(table).some(Boolean), 'phonesDuplicationTable'),
    phonesDuplicationTable: (0, _emberHelpers.computedApplyFunction)((addlContacts, sitePhoneNumbers) => {
      const {
        primary,
        secondary
      } = sitePhoneNumbers || {};
      return [...[].concat(...(addlContacts || []).map(c => c.phoneNumbers)), ...[primary, secondary].map(number => ({
        number
      }))].reduce((acc, {
        number: n,
        extension: e = ''
      }) => {
        const key = (0, _myadtHelpers.removePhoneMask)(n).concat(e);
        return (0, _fp.omix)(acc, {
          [key]: Boolean(key) && Object.keys(acc).includes(key)
        });
      }, {});
    }, 'addlContacts', 'sitePhoneNumbers'),

    /**
     * Identifies if a needed phone is a duplicate.
     * @instance
     *
     * @function
     * @param {String} phone
     *
     * @returns {Boolean}
     */
    determinePhoneDuplication(phone, ctx = this.get('phonesDuplicationTable'), ext = '') {
      return ctx[(0, _myadtHelpers.removePhoneMask)(phone).concat(ext)];
    },

    /**
     * Identifies if some phone from the list is a duplicate.
     * @instance
     *
     * @function
     * @param {String[]} phone
     *
     * @returns {Boolean}
     */
    determinePhoneListDuplication(phones = [], extensions = [], ctx) {
      return phones.some((p, i) => this.determinePhoneDuplication(p, ctx, extensions[i]));
    },

    /**
     * ADD or UPDATE PMOC data
     * Submit full PMOC object and service will make changes
     * @instance
     *
     * @type {ServiceCall}
     * @param {Object} arguments.pmoc
     * @param {PmocContact[]} arguments.pmoc.primary
     * @param {PmocContact[]} arguments.pmoc.billing
     * @param {PmocContact[]} arguments.pmoc.service
     * @param {Object} arguments.paperlessOption
     * @param {Boolean} arguments.paperlessOption.enrolled
     * @param {Boolean} arguments.paperlessOption.pending
     *
     * @returns {ApiResponse}
     */
    savePMOC({
      pmoc,
      paperlessOption
    }) {
      const pkg = this.unwrapPmocData(pmoc, paperlessOption);
      return this.get('apiService').myADT.savePmocData(pkg).then(() => this.getPMOC()).catch(errors => {
        const {
          messages = []
        } = errors;

        if (messages == null || typeof messages[0] === 'string' && messages[0] === 'The response was empty.') {
          console.warn('Server has returned empty response for the "savePMOC" action, it should be success action');
          return this.getPMOC();
        }

        throw errors;
      });
    },

    /**
    	 * ADD or UPDATE Monitoring Consent
    	 * Submit consent value as true/false and service will make changes
    	 * @instance
    	 *
    	 * @type {ServiceCall}
    	 *
    	 * @returns {ApiResponse}
    	 */
    updateMonitoringInfoConsent(payload) {
      return this.get('apiService').myADT.updateMonitoringInfoConsentData(payload).then(() => {
        this.getSharingPreferenceData();
      }).catch(errors => {
        const {
          messages = []
        } = errors; // if (messages == null || (typeof (messages[0]) === 'string' && messages[0] === 'The response was empty.')) {
        // 	console.warn('Server has returned empty response for the "savePMOC" action, it should be success action');
        // 	return this.getPMOC();
        // }

        throw errors;
      });
    },

    /**
     * @instance
     *
     * @type {ServiceCall}
     * @param {Object} reminder
     *
     * @returns {ApiResponse}
     */
    addTravelReminder(reminder) {
      const vacationStartDt = reminder.startDate.toZonedISOString();
      const vacationEndDt = reminder.endDate.toZonedISOString();
      const {
        contactEmail
      } = reminder;
      return this.get('apiService').myADT.addTravelReminder({
        vacationStartDt,
        vacationEndDt,
        contactEmail
      }).then(() => this.getTravelReminders()).catch(errors => {
        const {
          messages = []
        } = errors;

        if (typeof messages[0] === 'string' && messages[0] === 'The response was empty.') {
          console.error('Server return empty response for Add  "Travel reminders" action, it should be success action');
          this.getTravelReminders();
          return Ember.RSVP.Promise.resolve(1111);
        }

        throw errors;
      });
    },

    /**
     * @instance
     *
     * @type {ServiceCall}
     * @param {Object} reminder
     *
     * @returns {ApiResponse}
     */
    deleteTravelReminder(reminder) {
      return this.get('apiService').myADT.deleteTravelReminder({
        vacationReminderId: parseInt(reminder.vacationReminderId, 10)
      }).then(() => this.getTravelReminders()).catch(errors => {
        const {
          messages = []
        } = errors;

        if (typeof messages[0] === 'string' && messages[0] === 'The response was empty.') {
          console.error('Server return empty response for Delete "Travel reminders" action, it should be success action');
          return Ember.RSVP.Promise.resolve();
        }

        throw errors;
      }).finally(() => this.getTravelReminders());
    },

    /**
     * @instance
     *
     * @type {ServiceCall}
     * @param {Object} item
     * @param {String} item.primary
     * @param {String} item.secondary
     * @param {Boolean} item.isPrimary
     *
     * @returns {ApiResponse}
     */
    updateSiteNumbers(item, startTime) {
      const jobComments = [];
      const {
        primary,
        secondary,
        primaryChatFlag,
        secondaryChatFlag,
        primaryVideoSharingFlag,
        secondaryVideoSharingFlag,
        phoneType1,
        phoneType2
      } = this.get('originalValues');

      if (item.primary === primary && !secondary && item.secondary === secondary && JSON.parse(item.primaryChatFlag) === primaryChatFlag && JSON.parse(item.secondaryChatFlag) === secondaryChatFlag && JSON.parse(item.primaryVideoSharingFlag) === primaryVideoSharingFlag && JSON.parse(item.secondaryVideoSharingFlag) === secondaryVideoSharingFlag && item.phoneType1 === phoneType1 && item.phoneType2 === phoneType2) {
        return Ember.RSVP.Promise.reject({
          success: false,
          errors: ['No changes were made.']
        });
      }

      if (item.isPrimary) {
        jobComments.push({
          type: 'PPHU',
          text: 'Updated primary location phone number.'
        });
      }

      if (item.secondary) {
        if (secondary) {
          if (secondary !== item.secondary) {
            jobComments.push({
              type: 'SPHU',
              text: 'Updated secondary location phone number.'
            });
          }
        } else {
          jobComments.push({
            type: 'SPHU',
            text: 'Added secondary location phone number.'
          });
        }
      } else if (this.get('sitePhoneNumbers.secondary')) {
        jobComments.push({
          type: 'SPHU',
          text: 'Deleted secondary location phone number.'
        });
      }

      return this.get('apiService').myADT.updateSitePhoneNumbers({
        primaryContactNumber: (0, _myadtHelpers.removePhoneMask)(item.primary),
        secondaryContactNumber: (0, _myadtHelpers.removePhoneMask)(item.secondary),
        phoneType: item.isPrimary ? 'Primary' : 'Secondary',
        chatFlag1: JSON.parse(item.primaryChatFlag),
        chatFlag2: JSON.parse(item.secondaryChatFlag),
        chatVideoSharingFlag1: JSON.parse(item.primaryVideoSharingFlag),
        chatVideoSharingFlag2: JSON.parse(item.secondaryVideoSharingFlag),
        phoneType1: item.phoneType1,
        // Include phoneType1 in the payload
        phoneType2: item.phoneType2 // Include phoneType2 in the payload

      }).then(() => {
        if (jobComments.length > 0) {
          return this.createMultipleIncidentOrIssue(jobComments.map(comment => ({
            transactionType: comment.type,
            jobComments: comment.text,
            startTime
          })));
        }

        return;
      }).finally(() => this.getSiteNumbers());
    },

    /**
     * Updates an existing additional contact entry
     * @instance
     *
     * @type {ServiceCall}
     * @param {Object} item
     *
     * @returns {ApiResponse}
     */
    updateAddlContact(item, {
      startTime
    } = {
      startTime: new Date()
    }) {
      return this.get('apiService').myADT.updateContact(this.processContactPayload(item)).then(() => {
        const eventTagInfo = {
          eventTypeId: 'EEMGCONT',
          subEventTypeId: 'ADD'
        };
        this.get('ET').sendEventTag(eventTagInfo);

        _myadtHelpers.default.sendAnalyticEvent(['_trackEvent', 'Alarm Contacts', 'Add/Edit Contact', '']);

        this.createIncidentOrIssue('CONU', 'Updated additional contact information', startTime);
        return this.getContacts();
      });
    },

    processContactPayload(payload) {
      const data = payload || {};
      const {
        phoneNumbersSeqNumbers = []
      } = data;
      return (0, _fp.omix)((0, _fp.omit)(data, ['owner', 'mtmWithEmail', 'addMode', 'position', 'editMode', 'phoneNumbersSeqNumbers']), {
        phoneNumbers: data.phoneNumbers.map((p, i) => (0, _fp.omix)(p, {
          seqNo: phoneNumbersSeqNumbers[i],
          number: (0, _myadtHelpers.removePhoneMask)(p.number)
        }))
      });
    },

    /**
     * Reorder an additional contact
     * @instance
     *
     * @type {ServiceCall}
     * @param {Object} item
     *
     * @returns {ApiResponse}
     */
    reorderContacts(items, {
      type,
      startTime
    } = {
      startTime: new Date()
    }) {
      const IS_PRE_DISPATCH = type === 'predispatch';
      const oppositeList = this.get(IS_PRE_DISPATCH ? 'postDispatchContacts' : 'preDispatchContacts');
      const list = IS_PRE_DISPATCH ? items.concat(oppositeList) : oppositeList.concat(items);
      const payload = list.map(({
        contactNo,
        ctaclinkNo
      }) => ({
        contactNo,
        ctaclinkNo
      }));
      return this.get('apiService').myADT.reorderContacts(payload).then(() => {
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Alarm Contacts', 'Reorder Contacts', '']);
        this.createIncidentOrIssue('CLOU', 'Updated call order', startTime);
        return this.getContacts();
      });
    },

    /**
     * Adds an additional contact entry
     * @instance
     *
     * @type {ServiceCall}
     * @param {Object} item
     *
     * @returns {ApiResponse}
     */
    addAddlContact(item, {
      startTime
    } = {
      startTime: new Date()
    }) {
      return this.get('apiService').myADT.addContact(this.processContactPayload(item)).then(() => {
        const eventTagInfo = {
          eventTypeId: 'EEMGCONT',
          subEventTypeId: 'ADD'
        };
        this.get('ET').sendEventTag(eventTagInfo);
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Alarm Contacts', 'Add/Edit Contact', '']);
        this.createIncidentOrIssue('CONU', 'Added additional contact information', startTime);
        return this.getContacts();
      });
    },

    /**
     * Removes an additional contact entry
     * @instance
     *
     * @type {ServiceCall}
     * @param {Object} item
     *
     * @returns {ApiResponse}
     */
    removeContact(item, {
      startTime
    } = {
      startTime: new Date()
    }) {
      (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Alarm Contacts', 'DeleteContactRequested', '']);
      return this.get('apiService').myADT.removeContact({
        contactNo: item.contactNo,
        ctaclinkNo: item.ctaclinkNo
      }).then(() => {
        this.createIncidentOrIssue('CONU', 'Deleted additional contact information', startTime);
        const eventTagInfo = {
          eventTypeId: 'EEMGCONT',
          subEventTypeId: 'DELETE'
        };
        this.get('ET').sendEventTag(eventTagInfo);
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Alarm Contacts', 'DeleteContact', '']);
        return this.getContacts();
      });
    },

    /**
     * Refreshes `addlContacts` and `siteNumbers` from API
     * @instance
     *
     * @type {ServiceCall}
     * @returns {Promise.all}
     */
    refreshAllContacts() {
      return Ember.RSVP.Promise.all([this.getContacts(), this.getSiteNumbers()]);
    },

    /**
     * Refreshes `pmoc` and `travelReminders` from API
     * @instance
     *
     * @type {ServiceCall}
     * @returns {Promise.all}
     */
    refreshAllPmoc() {
      return Ember.RSVP.Promise.all([this.getPMOC(), this.getTravelReminders()]);
    },

    /**
     * Refreshes `addlContacts` and `restrictedOverrides` from API
     * @instance
     *
     * @type {ServiceCall}
     * @returns {ApiResponse}
     */
    getContacts() {
      this.clearAddlContacts();
      return this.get('apiService').myADT.getContacts().then(({
        data
      }) => {
        const addlContacts = Array.from(data.contacts).map(item => (0, _fp.omix)(item, {
          editMode: false,
          addMode: false,
          firstName: Ember.String.capitalize(item.firstName.toLowerCase()),
          lastName: Ember.String.capitalize(item.lastName.toLowerCase()),
          phoneNumbers: item.phoneNumbers.map(p => (0, _fp.omix)(p, {
            number: (0, _myadtHelpers.addPhoneMask)(p.number)
          })),
          phoneNumbersSeqNumbers: item.phoneNumbers.map(p => p.seqNo)
        }));
        const {
          preDispatchContacts,
          postDispatchContacts
        } = addlContacts.reduce((acc, contact) => {
          acc[contact.ecv ? 'preDispatchContacts' : 'postDispatchContacts'].push(contact);
          return acc;
        }, {
          preDispatchContacts: [],
          postDispatchContacts: []
        });
        this.set('preDispatchContacts', preDispatchContacts);
        this.set('postDispatchContacts', postDispatchContacts);
        this.set('restrictedOverrides', data.restrictedOverrides);
        this.set('addlContacts', addlContacts);
        return addlContacts;
      });
    },

    /**
     * Refreshes `sitePhoneNumbers` from API
     * @instance
     *
     * @type {ServiceCall}
     * @returns {ApiResponse}
     */
    getSiteNumbers() {
      this.set('sitePhoneNumbers', null);
      return this.get('apiService').myADT.getSiteNumbers().then(({
        data
      }) => {
        this.set('sitePhoneNumbers', {
          primary: (0, _myadtHelpers.addPhoneMask)(data.primaryContactNumber),
          secondary: (0, _myadtHelpers.addPhoneMask)(data.secondaryContactNumber),
          primaryChatFlag: data.chatFlag1 == null ? null : data.chatFlag1,
          secondaryChatFlag: data.chatFlag2 == null ? null : data.chatFlag2,
          primaryVideoSharingFlag: data.chatVideoSharingFlag1 == null ? null : data.chatVideoSharingFlag1,
          secondaryVideoSharingFlag: data.chatVideoSharingFlag2 == null ? null : data.chatVideoSharingFlag2,
          phoneType1: data.phoneType1 == null ? null : data.phoneType1,
          phoneType2: data.phoneType2 == null ? null : data.phoneType2
        });
        this.set('originalValues', JSON.parse(JSON.stringify({
          primary: (0, _myadtHelpers.addPhoneMask)(data.primaryContactNumber),
          secondary: (0, _myadtHelpers.addPhoneMask)(data.secondaryContactNumber),
          primaryChatFlag: data.chatFlag1 == null ? null : data.chatFlag1,
          secondaryChatFlag: data.chatFlag2 == null ? null : data.chatFlag2,
          primaryVideoSharingFlag: data.chatVideoSharingFlag1 == null ? null : data.chatVideoSharingFlag1,
          secondaryVideoSharingFlag: data.chatVideoSharingFlag2 == null ? null : data.chatVideoSharingFlag2,
          phoneType1: data.phoneType1 == null ? null : data.phoneType1,
          phoneType2: data.phoneType2 == null ? null : data.phoneType2
        })));
      });
    },

    /**
     * Refreshes `pmocData` from API
     * @instance
     *
     * @type {ServiceCall}
     * @returns {ApiResponse}
     */
    getPMOC() {
      this.set('pmocData', null);
      return this.get('apiService').myADT.getPmocData().then(({
        data
      }) => this.wrapPmocData(data));
    },

    /**
     * Reset additional contacts
     * @instance
     */
    clearAddlContacts() {
      ['preDispatchContacts', 'postDispatchContacts', 'addlContacts'].forEach(key => this.set(key, []));
    },

    /**
     * Takes PMOC array from API and converts to `pmocData` structure,
     * then popuates `pmocData`
     * @param {PmocResponseArray} _data
     *
     * @return {Object}
     * @example {
     * 	primary: [{ email: 'test@test.test', pending: null }],
     * 	service: [],
     * 	billing: [{ email: 'test@test.test', pending: null }, { email: 'tester@test.eu', pending: null }]
     * }
     */
    wrapPmocData(_data) {
      // fix empty primary contact
      const data = (0, _emberCopy.copy)(_data);
      const {
        notifications
      } = data;
      const out = {
        contacts: notifications.contacts,
        primary: [],
        billing: [],
        service: []
      }; // check for no primaries, or all primaries pending deletion

      notifications.forEach(notification => {
        out[notification.name.toLowerCase()] = [].concat(notification.contacts);
      });

      if (out.primary.length === 0 || out.primary.every(e => e === null)) {
        out.primary = [{
          email: '',
          pending: null
        }];
      }

      this.set('paperlessOption', data.paperlessOption);
      this.set('pmocData', out);
      data.notifications = out;
      return data;
    },

    /**
     * Takes `pmocData` and converts to proper array for submission to API
     * @return {PmocResponseArray}
     */
    unwrapPmocData(pmocData, paperlessOption) {
      const pmocState = this.get('pmocData');
      const data = (0, _fp.omix)(pmocState, pmocData);
      const out = {
        paperlessOption,
        contacts: data.contacts,
        notifications: []
      };
      Object.keys(data).forEach(key => {
        if (key !== 'contacts') {
          out.notifications.push({
            name: key.toUCFirst(),
            contacts: (data[key] || []).filter(c => c.pending !== 'D' && !c.deleted && c.email)
          });
        }
      });
      return out;
    },

    /**
     * Refreshes `travelReminders` from API
     * @instance
     *
     * @type {ServiceCall}
     * @returns {ApiResponse}
     */
    getTravelReminders() {
      this.set('travelReminders', null);
      return this.get('apiService').myADT.getTravelReminders().then(({
        data
      }) => {
        const reminders = (data || []).map(reminder => {
          const x = reminder;
          x.startDate = Date.createDate(x.vacationStartDt);
          x.endDate = Date.createDate(x.vacationEndDt);
          x.createdDate = Date.createDate(x.reminderCreatedDt);
          return x;
        });
        return this.set('travelReminders', reminders);
      }).finally(() => this.trigger('travelRemindersComplete'));
    },

    /**
     * Returns contact data in HTML for print
     * @instance
     *
     * @type {ServiceCall}
     * @returns {ApiResponse}
     */
    printContactData() {
      return this.get('apiService').myADT.printContactData().catch(err => {
        if (typeof err.data === 'string') {
          return Ember.RSVP.Promise.resolve(err);
        }

        return Ember.RSVP.Promise.reject(err);
      }).then(({
        data
      }) => {
        const windowReference = window.open();
        windowReference.document.write(data);
      });
    },

    /**
     * Returns contact data in .xlsx
     * @instance
     *
     * @type {ServiceCall}
     * @returns {ApiResponse}
     */
    exportContactData({
      startTime
    } = {
      startTime: new Date()
    }) {
      return this.get('apiService').myADT.exportContactData().then(() => {
        const csrUser = this.get('authInfo.authInfo.csrUser');
        const searchId = sessionStorage.getItem('chudlySearchId');
        let url = "".concat((0, _myadtHelpers.getOrigin)()).concat(_environment.default.rootURL, "ReportServlet?reportType=ContactsReport");

        if (csrUser) {
          url += "&searchId=".concat(searchId || '');
        }

        const windowReference = window.open(url);
        (0, _myadtHelpers.sendAnalyticEvent)(['_trackEvent', 'Contacts', 'Download Contacts', 'fileType=xls']);
        this.createIncidentOrIssue('DLCR', 'Downloaded contacts report to Excel', startTime);
        windowReference.focus();
      });
    },

    /**
     * Populates `phoneConsents`
     * @instance
     *
     * @type {ServiceCall}
     * @returns {ApiResponse}
     */
    getCustomerPhoneConsents() {
      return this.get('apiService').myADT.getCustomerPhoneConsents().then(({
        data
      }) => this.set('phoneConsents', data));
    },

    /**
     * Populates `sharingPreferenceData`
     * @instance
     *
     * @type {ServiceCall}
     * @returns {ApiResponse}
     */
    getSharingPreferenceData() {
      return this.get('apiService').myADT.getMonitoringInfoConsent().then(({
        data
      }) => this.set('sharingPreferenceData', data));
    },

    /**
     * @param {Object} consents
     * @param {String} consents.phone1 unmasked
     * @param {String} consents.phone1Type Landline|Mobile
     * @param {String} consents.phone2 unmasked
     * @param {String} consents.phone2Type Landline|Mobile
     */
    updatePhoneNumbers(consents) {
      const {
        phone1,
        phone1Type,
        phone2,
        phone2Type
      } = consents;
      return this.get('apiService').myADT.updatePhoneNumbers({
        phone1: (0, _myadtHelpers.removePhoneMask)(phone1),
        phone1Type,
        phone2: (0, _myadtHelpers.removePhoneMask)(phone2),
        phone2Type
      }).finally(() => this.getCustomerPhoneConsents());
    },

    /**
     * @param {Object} consents
     * @param {Boolean} consents.phone1BillingTextConsent
     * @param {Boolean} consents.phone2BillingTextConsent
     */
    updateBillingConsents(consents) {
      const data = (0, _fp.omit)(consents, 'phone1SrvcApptTextConsent', 'phone2SrvcApptTextConsent');
      data.phone1 = (0, _myadtHelpers.removePhoneMask)(consents.phone1);
      data.phone2 = (0, _myadtHelpers.removePhoneMask)(consents.phone2);
      return this.get('apiService').myADT.updateBillingConsents(data).finally(() => this.getCustomerPhoneConsents());
    },

    /**
     * @param {PmocPhoneConsentsObject} consents
     * @param {String} consents.phone1SrvcApptTextConsent
     * @param {String} consents.phone2SrvcApptTextConsent
     */
    updateServiceAppointmentConsents(consents) {
      const data = (0, _fp.omit)(consents, 'phone1BillingTextConsent', 'phone2BillingTextConsent');
      data.phone1 = (0, _myadtHelpers.removePhoneMask)(consents.phone1);
      data.phone2 = (0, _myadtHelpers.removePhoneMask)(consents.phone2);
      return this.get('apiService').myADT.updateServiceAppointmentConsents(data).finally(() => this.getCustomerPhoneConsents());
    },

    /**
     * @param {Object} data
     * @param {String} data.phone1
     * @param {Boolean} data.consent
     *
     * @return {Promise}
     */
    updateProductsAndOffersConsents(data) {
      return this.apiService.myADT.updateProductsAndOffersConsents(data).finally(() => this.getCustomerPhoneConsents());
    },

    /**
     * Creates MMB Incident
     * calls {@link Services/ImpersonationData.createIncidentOrIssue}
     * @param {String} transactionType
     * @param {String} jobComments
     * @param {Timestamp} startTime
     */
    createIncidentOrIssue(transactionType, jobComments, startTime = new Date()) {
      const headerInfo = this.get('impersonationData.headerInfo');
      const issueData = {
        customerNo: headerInfo ? headerInfo.customer.custNo : 0,
        siteNo: headerInfo ? headerInfo.site.siteNo : 0,
        duration: Date.now() - startTime,
        transactionType,
        jobComments
      };
      return this.get('mmbIncidents').createIncidentOrIssue(issueData);
    },

    /**
     * Creates MMB Incident
     * calls {@link Services/ImpersonationData.createMultipleIncidentOrIssue}
     * @param {Object[]} issues
     * @param {String} issues.{transactionType}
     * @param {String} issues.{jobComments}
     * @param {Timestamp} issues.{startTime}
     */
    createMultipleIncidentOrIssue(issues) {
      if (!issues || !issues.length) return Ember.RSVP.Promise.reject({
        success: false,
        errors: ['No data submitted.']
      });
      const headerInfo = this.get('impersonationData.headerInfo');
      const issueData = issues.map(({
        transactionType,
        jobComments,
        startTime
      }) => ({
        customerNo: headerInfo ? headerInfo.customer.custNo : 0,
        siteNo: headerInfo ? headerInfo.site.siteNo : 0,
        duration: Date.now() - startTime,
        transactionType,
        jobComments
      }));
      return this.get('mmbIncidents').createMultipleIncidentOrIssue(issueData);
    },

    [_appDispatcher.UPDATE_APP_STATE_EVENT]() {
      return Ember.RSVP.Promise.all([this.refreshAllContacts(), this.refreshAllPmoc()]);
    },

    init() {
      this._super(...arguments);

      this.travelReminders = [];
      this.pmocData = null;
      this.get('appDispatcher').subscribe(_appDispatcher.UPDATE_APP_STATE_EVENT, this, _appDispatcher.UPDATE_APP_STATE_EVENT);
    }

  });

  _exports.default = _default;
});